<template>
  <div v-if="dialogVisible" class="custom-dialog">
    <div class="dialog-content">
      <el-form ref="uploadForm" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="上传图片" prop="image">
          <el-upload
            class="upload-demo"
            :before-upload="handleImageUpload"
            :file-list="fileList"
            list-type="picture"
            accept="image/*"
          >
            <el-button slot="trigger" size="small" type="primary"
              >选取图片</el-button
            >
          </el-upload>
          <img
            v-if="imageUrl"
            :src="imageUrl"
            alt="Uploaded Image"
            class="uploaded-image"
          />
        </el-form-item>
        <el-form-item label="类别" prop="category">
          <el-select
            v-model="form.category"
            placeholder="请选择类别"
            @change="handleCategoryChange"
          >
            <el-option
              v-for="item in categoryOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="标题" prop="title">
          <el-input v-model="form.title"></el-input>
        </el-form-item>
        <el-form-item label="链接" prop="link">
          <el-input v-model="form.link"></el-input>
        </el-form-item>
        <el-form-item>
          <!-- <el-button v-if="!isEditMode" type="primary" @click="uploadResource">
            发布
          </el-button>
          <el-button v-else type="primary" @click=""> 修改 </el-button> -->
          <el-button type="primary" @click="handleSubmit">{{
            isEditMode ? "修改" : "发布"
          }}</el-button>
          <el-button @click="cancel">取消</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import {
  planUpLoad,
  getPic,
  addResourceCentre,
  updateResourceCentre,
  getCentreGlobalList,
} from "../../request/index";

export default {
  props: {
    dialogVisible: {
      type: Boolean,
      required: true,
    },
    editRow: {
      type: Object,
      default: null,
    },
    isEditMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        title: "",
        link: "",
        image: null,
        category: "",
      },
      fileList: [],
      imageUrl: "",
      fileContent: null,
      categoryOptions: [],
      selectedCategory: null,
    };
  },
  watch: {
    dialogVisible(newVal) {
      if (!newVal) {
        this.resetForm();
      }
    },
    editRow: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.setFormValues(newVal);
        }
      },
    },
  },
  mounted() {
    this.fetchCategoryOptions();
  },
  methods: {
    async fetchCategoryOptions() {
      try {
        const response = await getCentreGlobalList();
        this.categoryOptions = response.data.map((item) => ({
          label: item.codeName, // 假设接口返回的数据中有name字段
          value: item.code, // 假设接口返回的数据中有id字段
        }));
      } catch (error) {
        console.error("Error fetching category options:", error);
      }
      console.log(this.categoryOptions);
    },
    handleCategoryChange(value) {
      console.log(this.categoryOptions, "22222222222");
      this.selectedCategory = this.categoryOptions.find(
        (item) => item.value === value
      );
      console.log(this.selectedCategory, "888888888888");
      // 如果需要获取选中的类别对象，可以使用以下代码
      // const selectedCategory = this.categoryOptions.find(item => item.value === value);
      // console.log('选中的类别对象:', selectedCategory);
    },
    async handleImageUpload(file) {
      // 将文件赋值给 form 对象
      this.form.image = file;

      this.fileContent = file;
      // 更新文件列表
      this.fileList = [file];
      // 创建 Blob URL 用于预览
      this.imageUrl = URL.createObjectURL(file);

      //   this.fileContent = new FormData();
      //   this.fileContent.append("image", file);

      return false;
    },
    async handleSubmit() {
      if (this.isEditMode) {
        await this.updateResource();
      } else {
        await this.uploadResource();
      }
    },
    //发布
    async uploadResource() {
      // if (
      //   !this.form.image ||
      //   !this.form.title ||
      //   !this.form.link ||
      //   !!this.form.category
      // ) {
      //   alert("请填写所有字段");
      //   return;
      // }
      const formData = new FormData();
      formData.append("avatar", this.fileContent);

      const planUpLoadRes = await planUpLoad({ file: this.fileContent });

      const getPicRes = await getPic({ url: planUpLoadRes.data });

      const addData = {
        wxUrl: this.form.link,
        picUrl: planUpLoadRes.data,
        title: this.form.title,
        centreCode: this.selectedCategory.value,
        centreName: this.selectedCategory.label, // 选中的类别名称
      };
      console.log(addData, "999999999999");
      // return;
      const addRes = await addResourceCentre(addData);

      if (addRes.code === 200) {
        this.$message({
          message: "发布成功",
          type: "success",
        });
        this.$emit("refreshList");
        this.handleRemove();
        this.cancel();
      } else {
        this.$message.error("发布失败");
      }
    },

    async updateResource() {
      try {
        const formData = new FormData();
        formData.append("avatar", this.fileContent);

        const planUpLoadRes = await planUpLoad({ file: this.fileContent });

        // 检查 selectedCategory 是否为 null 或 undefined
        const selectedCategory = this.selectedCategory || {};
        const updateData = {
          id: this.editRow.id,
          wxUrl: this.form.link,
          picUrl: planUpLoadRes.data,
          title: this.form.title,
          centreCode: selectedCategory.value || "", // 如果为空，使用默认空字符串
          centreName: selectedCategory.label || "", // 如果为空，使用默认空字符串
        };
        console.log(updateData, "3333333333333");

        const updateRes = await updateResourceCentre(updateData);
        if (updateRes.code === 200) {
          this.$message({
            message: "修改成功",
            type: "success",
          });
          this.$emit("refreshList");
          this.handleRemove();
          this.cancel();
        } else {
          this.$message.error("修改失败");
        }
      } catch (error) {
        this.$message.error("发生错误: " + error.message);
      }
    },

    handleRemove() {
      this.form.image = null;
      this.fileList = [];
      this.imageUrl = "";
      this.selectedCategory = [];
    },

    resetForm() {
      this.form.title = "";
      this.form.link = "";
      this.form.image = null;
      this.form.category = "";
      this.fileList = [];
      this.imageUrl = "";
    },
    cancel() {
      this.$emit("update:dialogVisible", false);
    },
    setFormValues(row) {
      this.form.title = row.title || "";
      this.form.link = row.wxUrl || "";
      this.imageUrl =
        `http://192.168.0.182:8080/searchCompany/getPic?url=` + row.picUrl;
      this.form.category = row.centreName;
    },
  },
};
</script>

<style scoped>
.custom-dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.dialog-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  width: 80%;
  max-width: 600px;
  position: relative;
}

.upload-demo .el-upload {
  display: inline-block;
}

.uploaded-image {
  margin-top: 10px;
  max-width: 100%;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 2px;
}

.el-form-item {
  margin-bottom: 20px;
}

.el-button {
  margin-right: 10px;
}

.el-button[type="primary"] {
  background-color: #409eff;
  border-color: #409eff;
}

.el-button[type="success"] {
  background-color: #67c23a;
  border-color: #67c23a;
}

.el-button[type="success"]:hover {
  background-color: #4caf50;
  border-color: #4caf50;
}
</style>
